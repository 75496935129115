import logo from './logo.svg';
import './App.css';
import { ChakraProvider } from '@chakra-ui/react';
import ParticipantInterestForm from './ParticipantInterestForm';

function App() {
  return (

    <ChakraProvider>
      <ParticipantInterestForm/>
    </ChakraProvider>
  );
}

export default App;
